<template>
  <form action="" @submit.prevent="save">
    <slot name="header">

    </slot>
    <div
      class="success message"
      role="alert"
      name="success-message"
      v-if="showSuccessMessage && isSuccess"
    >
      <slot name="success-message" :data="responseData">
        <p><translate translate-context="*/*/*">Form submitted successfully.</translate></p>
      </slot>
    </div>

    <field-errors :errors="errors.nonFieldErrors" />

    <slot name="form-body" :errors="errors" :data="data">

    </slot>

    <hr class="hidden">
    <div class="controls">
      <slot name="form-controls-before"></slot>
      <app-button :is-loading="isLoading" type="submit" v-bind="submitProps">
        <slot name="form-submit" :data="data">
          <translate translate-context="*/Button/Verb">Submit</translate>
        </slot>
      </app-button>
      <slot name="form-controls-after"></slot>
    </div>
  </form>
</template>
<script>
import http from '@/http'

export default {
  props: {
    endpoint: {required: true},
    idField: {required: false, default: 'id'},
    obj: {required: false},
    additionalData: {required: false, default: () => {return {}}},
    defaultData: {required: false, default: () => {return {}}},
    updateMethod: {required: false, default: "patch"},
    createMethod: {required: false, default: "post"},
    showSuccessMessage: {required: false, default: true},
    resetOnSave: {required: false, default: false},
    prepareSave: {required: false, default: () => {return (d) => {return d}}},
    submitProps: {required: false, default: () => {return {}}},
    responseAttr: {type: String, required: false, default: null},
  },
  data () {
    return {
      isLoading: false,
      errors: {},
      isSuccess: false,
      data: {...(this.obj || this.defaultData)},
      responseData: null,
    }
  },
  methods: {
    async save () {
      let response
      this.isSuccess = false
      this.isLoading = true
      this.errors = {}
      this.responseData = null
      let data = {...this.data, ...this.additionalData}
      data = this.prepareSave(data)
      let id = this.idField ? data[this.idField] : null
      let url = id ? `${this.endpoint}/${id}` : this.endpoint
      if (id) {
        response = http[this.updateMethod](url, data)
      } else {
        response = http[this.createMethod](url, data)
      }
      try {
        response = await response
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }

      if (response.data) {
        this.responseData = response.data
        if (this.responseAttr) {
          this.responseData = this.responseData[this.responseAttr]
        }
        this.data = this.responseData
        if (this.resetOnSave) {
          this.data = {...(this.obj || this.defaultData)}
        }
        this.isSuccess = true
        this.$emit("save", this.responseData)
      }
      this.$nextTick(() => {
        let alert = this.$el.querySelectorAll('[role="alert"]')[0]
        if (alert) {
          alert.scrollIntoView()
        }
      })
    }
  }
}
</script>

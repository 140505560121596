export default {
  "locales": [
    {
      "code": "en_US",
      "label": "English (United-States)"
    },
    {
      "code": "fr_FR",
      "label": "Français"
    }
  ]
}

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper"  @click.self="$emit('close')">
        <div class="modal-container">

          <div class="modal-header">
            <h2>
              <slot name="header">

              </slot>
            </h2>
          </div>

          <div class="modal-body">
            <slot>

            </slot>
          </div>
          <hr>
          <div class="modal-footer">
            <slot name="footer">
              <div class="controls" v-if="submitAction">
                <button class="link" @click.prevent="$emit('close')">
                  <translate translate-context="*/*/*">Cancel</translate>          
                </button>
                <slot name="action-button">
                  <button @click.prevent="submitAction()">
                    <translate translate-context="*/*/*">Validate</translate>          
                  </button>
                </slot>
              </div>
              <button v-else @click.prevent="$emit('close')">
                <translate translate-context="*/*/*">Close</translate>          
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  props: {
    submitAction: {}
  },
  mounted () {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit('close');
      }
    });
  }
}
</script>